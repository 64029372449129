import './App.css'
import  {useState} from "react"
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import Menu from './Components/Menu'
import Navbar from './Components/Navbar'
import BigImage from './Components/BigImage'
import UnitInPreview from './Components/UnitInBigImage'

function App() {
  const cards = window.data.cards
  const mainTitle = window.data.mainTitle
  const mainImage = window.data.mainImage
  const [unitPreviewVisible, setUnitPreviewVisible] = useState(false)
  const [unitPreviewOtherProps, setUnitPreviewOtherProps] = useState({})

  const setPropFroUnitInBigImage = (visible, src, srcMainImage, bigImageProp) => {
    const propsObj = {
      src: src,
      srcMainImage: srcMainImage,
      bigImageProp: bigImageProp
    }
    setUnitPreviewVisible(visible)
    setUnitPreviewOtherProps(propsObj)
    document.querySelector("body").style.overflow = "hidden"
  }

  const hideUnitPreview = (visible) => {
    setUnitPreviewVisible(visible)
    document.querySelector("body").style.overflow = "unset"
  }

  return (
    <div className="App h-100" >
      <header className="d-flex bg-dark justify-content-center">
        <Navbar mainTitle={mainTitle} />
      </header>
      <main className="w-100">
        <UnitInPreview visible={unitPreviewVisible} unitPreviewOtherProps={unitPreviewOtherProps} hideUnitPreview={hideUnitPreview} />
        <div className="main d-flex h-100 w-100">
          <div className="menu-container p-0 h-100 bg-dark overflow-auto">
            <Menu cards={cards} mainImage={mainImage} setPropFroUnitInBigImage={setPropFroUnitInBigImage} />
          </div>
          <div className="bigImgContainer p-0 d-none d-lg-flex h-100 w-100 align-items-center justify-content-center">
            <BigImage cards={cards} mainImage={mainImage} bigImage={true} image={null} closeButton={false} />
          </div>
        </div>
      </main >
    </div>
  );
}

export default App;