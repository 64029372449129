function BigImage(props) {
  const { cards } = props
  const { bigImage } = props
  const { mainImage } = props
  const { imageOfUnitInPreview } = props

  const getClassesForMainDiv = (bigImage) => {
    return bigImage ? "position-relative top-left" : "position-relative top-left vertical-center"
  }

  const getClassesForMainImage = (bigImage) => {
    return bigImage ? "main-imagePreview top-left"  : "card-imageOpacity d-flex vertical-center"
  }

  const getSmallImage = (imageOfUnitInPreview) => {
    return imageOfUnitInPreview ? <img className="position-absolute top-left d-flex vertical-center" src={"img/" + imageOfUnitInPreview} alt={imageOfUnitInPreview} /> : null
  }

  return (
    <div>
      <div className={getClassesForMainDiv(bigImage)}>
        <img className={getClassesForMainImage(bigImage)} src={"img/" + mainImage} alt={mainImage} />
        {bigImage ?
          cards.map((card, index) => {
            return (
              <img className={index + " small-imageUnit  top-left h-100"} src={"img/" + card.img} key={index} alt={card.smallImage} />
            )
          })
          : getSmallImage(imageOfUnitInPreview)}
      </div>
    </div>
  )
}

export default BigImage;