import BigImage from "./BigImage"

function UnitInPreview(props) {
    const { visible } = props
    const { hideUnitPreview } = props
    const { unitPreviewOtherProps } = props

    return (
        visible ?
            <div className="UnitInPreview d-flex w-100 position-fixed h-100 top-left justify-content-center">
                <div className="content-UnitPreview bg-white d-flex position-relative">
                    <BigImage  cards={null} mainImage={unitPreviewOtherProps.srcMainImage} imageOfUnitInPreview={unitPreviewOtherProps.src} bigImage={unitPreviewOtherProps.bigImageProp} />
                    <button onClick={() => { hideUnitPreview(false) }} className="btn btn-sm btn-danger position-absolute">
                        <i className="fa-solid fa-xmark text-dark"></i>
                    </button>
                </div>
            </div>
            : null
    );
}

export default UnitInPreview;