function Navbar(props) {
    const { mainTitle } = props

    return (
        <div className="Navbar d-flex p-1 align-items-center">
            <img src="img/A.svg" alt={"A.svg"} className="icon p-0 rounded align-items-center"></img>
            <h1 className="text-center m-0 p-1">
                {mainTitle}
            </h1>
        </div>
    );
}

export default Navbar;