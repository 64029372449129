import CardMainContent from "./CardMainContain"

function Card(props) {
    const { link } = props
    const { title } = props
    const { paragraph } = props
    const { mainImage } = props
    const { buttontext } = props
    const { classOfCard } = props
    const { imageOfUnit } = props
    const { imageOfUnitInPreview } = props
    const { setPropFroUnitInBigImage } = props

    const detectMouseOverOrleave = (classname, displayProp) => {
        const elements = document.getElementsByClassName(classname)
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index]
            element.style.visibility = displayProp
        }
    }

    return (
        <div className="card rounded ms-2 mb-2 me-2 me-md-2 mb-md-2">
            <a href={link} target="blank"  onMouseOver={() => { detectMouseOverOrleave(classOfCard, "visible") }} onMouseLeave={() => { detectMouseOverOrleave(classOfCard, "hidden") }}>
                <CardMainContent imageOfUnit={imageOfUnit} imageOfUnitInPreview={imageOfUnitInPreview} mainImage={mainImage} link={link} title={title} paragraph={paragraph} buttontext={buttontext} />
            </a>
            <div className="d-flex justify-content-end">
                <button onClick={() => { setPropFroUnitInBigImage(true, imageOfUnitInPreview, mainImage, false) }} className="btn btn-sm btn-warning  d-sm-none">
                    <i className="fa-solid fa-magnifying-glass text-dark"></i>
                </button>
            </div>
        </div>
    );
}

export default Card;