import Card from "./Card/Card"

function Menu(props) {
  const { cards } = props
  const { mainImage } = props
  const { setPropFroUnitInBigImage } = props

  return (
    <div className="Menu">
      {
        cards.map((card, index) => {
          return (
            <div key={index}>
              <Card mainImage={mainImage} classOfCard={index} title={card.title} link={card.link} paragraph={card.paragraph} buttontext={card.buttontext} imageOfUnit={card.smallImage} imageOfUnitInPreview={card.img} setPropFroUnitInBigImage={setPropFroUnitInBigImage} />
            </div>
          )
        })
      }
    </div>
  );
}

export default Menu;